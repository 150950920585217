<template>
  <div style="display: flex;justify-content: center">
    <div class="search">
      <el-input 
        :placeholder="$t('app.whereTo')" 
        v-model="searchInput" 
        style="color: #a0a1a1;font-size: 18px;padding-left: 50px;" 
        @keyup.enter.native="keyUp"
        @focus="viewDialog"/>
      <span class="search_icon"><img src="../../assets/img/search.png" alt=""></span>
    </div>
    <div class="van-overlay" :style="{'z-index': '2019', visibility: visible}" @click="visible = 'hidden'"></div>
    <div
      :style="{'border-radius': '.1rem', 'background': '#ffffff', 'position': 'absolute',
      'width': '808px', 'top': '3.2rem', 'z-index': '2020', 'visibility': visible}"
    >
      <div class="popup">
        <el-input
          :placeholder="$t('app.whereTo')" 
          style="color: #a0a1a1;font-size: 17.5px;padding-left: 50px;"
          v-model="searchInput"
          @keyup.enter.native="keyUp"
        />
        <span class="search_icon"><img src="../../assets/img/search.png" alt=""></span>
      </div>
      <div class="divider"></div>
      <div class="po_bordy">
        <div class="po_title">{{ $t('app.recommendedScenicSpots') }}</div>
        <div style="">
          <div v-for="(item, index) in scenicList" :key="index" @click="toDetail(item)">
            <div class="content">
              <img :src="item.img"/>
              <div class="r_con">
                <div class="r_name">{{ item.name }}</div>
                <div class="r_desc">{{ item.desc | filtersText }}</div>
              </div>
            </div>
            <div class="con_divider"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apis from "../../utils/apis";
let { indexRecommend } = apis;

export default {
  name: "searchBtn",
  data () {
    return {
      searchInput: '',
      visible: 'hidden',
      dialogTop: '',
      dialogLeft: '',
      scenicList: '',
    }
  },
  created() {
    this.getScenicSpot();
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
  methods: {
    viewDialog() {
      this.visible = 'visible';
    },
    toSearch() {
      this.$router.push('/search')
    },
    keyUp() {
      this.visible = true;
      this.$router.push({
        path: '/searchClassify', 
        query: {
          title: this.searchInput
        }
      });
    },
    getScenicSpot() {
      indexRecommend({type: "1"}).then(res => {
        this.scenicList = res.data.data;
      })
    },
    toDetail(item) {
      this.$router.push({
        path: "essayDetail",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
  },
}
</script>

<style scoped lang="less">
.search {
  width: 808px;
  height: 60px;
  background: white;
  color: black;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  position: absolute;
  top: 3.2rem;
  font-size: 0.26rem;
  z-index: 100;
  .search_icon{
    position: absolute;
    height: 0.9rem;
    height: 0.9rem;
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      height: 20px;
    }
  }
  // .selectInputIcon {
    // position: relative;
    // margin-left: 1rem;
    // width: 0.28rem;

    // &::before {
    //   content: '';
    //   width: 0.2rem;
    //   height: 0.2rem;
    //   border-radius: 50%;
    //   border: 1px solid #000000;
    //   display: block;
    // }

    // &::after {
    //   content: '';
    //   width: 0.05rem;
    //   height: 0.01rem;
    //   background: #000000;
    //   display: block;
    //   position: absolute;
    //   transform: rotate(45deg);
    //   right: 0.02rem;
    //   bottom: 0;
    // }


  // }

}
.popup {
  display: flex;
  align-items: center;
  font-size: 0.26rem;
  height: 60px;
  .search_icon{
    position: absolute;
    height: 0.9rem;
    height: 0.9rem;
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      height: 20px;
    }
  }
}
.divider {
  height: 2px;
  background: #000000;
  width: 96%;
  margin: 0 auto;
}
.po_bordy {
  padding: 0 20px;
  text-align: left;
  font-size: 14px;
  .po_title {
    padding: 10px 0;
  }
  .content {
    margin: 10px 0;
    height: 0.8rem;
    display: flex;
    position: relative;
    cursor: pointer;
    img {
      height: 100%;
      width: 0.8rem;
      object-fit: cover;
      border-radius: .08rem;
    }
    .r_con {
      margin-left: 3%;
      .r_name {
        width: 85%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        top: 0;
      }
      .r_desc {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 0.4rem;
        line-height: 0.2rem;
        position: absolute;
        bottom: 0;
      }
    }
  }
  .con_divider {
    height: 1px;
    background: #e0e0e0;
  }
}
/deep/ .el-input__inner {
  border: none;
  width: 90%;
}
/deep/ .el-dialog {
  position: absolute;
  border-radius: .16rem;
  .el-dialog__header {
    display: none;
  }
}
</style>