<template>
  <div class="categoryDiv">

    <div class="typeDiv">
      <div class="typeItem" v-for="(item,index) in categoryList" :key="index" @click="goSearch(item.name,item.index)" 
                @mouseenter="mouseEnter(item,index)" @mouseleave="mouseLeave(item,index)">

        <div class="title">{{ item.title }}</div>
        <img :src="item.img" class="img">

      </div>

    </div>


  </div>
</template>

<script>

import introduction1 from "../../assets/img/introduction.png"
import area1 from "../../assets/img/area.png"
import scenicSpot1 from "../../assets/img/scenic spot.png"
import shop1 from "../../assets/img/shop.png"
import ticket1 from "../../assets/img/ticket.png"

import introductio_black from "../../assets/img/introduction_black.png"
import area_black from "../../assets/img/area_black.png"
import scenicSpot_black from "../../assets/img/scenic spot_black.png"
import shop_black from "../../assets/img/shop_black.png"
import ticket_black from "../../assets/img/ticket_black.png"


export default {
  name: "categoryBar",
  props: {},
  data() {
    return {
      mouseEvents: false,
      categoryList: [
        {
          title: this.$t('app.introduction'),
          img: introductio_black,
          type: 0,
          name:'b',
          index:'4'
        },
        {
          title: this.$t('app.area'),
          img: area_black,
          type: 0,
          name:'c',
          index:'1'
        },
        {
          title: this.$t('app.scenicSpot'),
          img: scenicSpot_black,
          type: 0,
          name:'d',
          index:'2'
        },
        {
          title: this.$t('app.shop'),
          img: shop_black,
          type: 0,
          name:'e',
          index:'5'
        },
        {
          title: this.$t('app.ticket'),
          img: ticket_black,
          type: 0,
          name:'f',
          index:'6'
        }
      ]
    }
  },
  create(){
    this.$forceUpdate()
  },
  methods: {
    goSearch(name,index){
      this.$emit('goSearch',name,index)
    },
    mouseEnter(item, index) {
      switch (index) {
        case 0: 
         item.img = introduction1;
         return;
        case 1: 
         item.img = area1;
         return;
        case 2: 
         item.img = scenicSpot1;
         return;
        case 3: 
         item.img = shop1;
         return;
        case 4: 
         item.img = ticket1;
         return;
      }
    },
    mouseLeave(item, index) {
      switch (index) {
        case 0: 
         item.img = introductio_black;
         return;
        case 1: 
         item.img = area_black;
         return;
        case 2: 
         item.img = scenicSpot_black;
         return;
        case 3: 
         item.img = shop_black;
         return;
        case 4: 
         item.img = ticket_black;
         return;
      }
    }
  },

}
</script>

<style scoped lang="less">
.categoryDiv {
  z-index: 999;
  width: 1136px;
  margin: 110px auto 30px;
  .typeDiv {
    display: flex;
    justify-content: space-between;
  }


  .typeItem:hover {
    background: #000000;
    .title {
      color: #fff;
    }
  }

  .typeItem {
    display: inline-flex;
    background: transparent;
    height: 47px;
    border-radius: .1rem;
    border: 1px solid #000000;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 20px;
    box-sizing: border-box;
    width: 207px;
    cursor: pointer;
    transform: scale(0.99);
    .title {
      color: #000000;
      display: flex;
      font-size: 15px;
      font-weight: 600;
    }

    .img {
      display: flex;
      width: 28px;
      height: 28px;

    }
  }


}

</style>
